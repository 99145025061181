@import url('https://fonts.googleapis.com/css2?family=Kanit:ital,wght@0,700;0,800;1,700;1,800&display=swap');
body{
        overflow-x: hidden;
        
    }


::-webkit-scrollbar{
        width:20px;
}
::-webkit-scrollbar-thumb{
        background-color:#0d6efd;
        border:5px solid transparent;
        border-radius: 9px;
        background-clip:content-box;
        
}
    .circles{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        overflow: hidden;
    }
    
    .circles li{
        position: absolute;
        display: block;
        list-style: none;
        width: 20px;
        height: 20px;
        background: rgba(255, 255, 255, 0.2);
        animation: animate 25s linear infinite;
        bottom: -150px;
        
    }
    
    .circles li:nth-child(1){
        left: 25%;
        width: 80px;
        height: 80px;
        animation-delay: 0s;
    }
    
    
    .circles li:nth-child(2){
        left: 10%;
        width: 20px;
        height: 20px;
        animation-delay: 2s;
        animation-duration: 12s;
    }
    
    .circles li:nth-child(3){
        left: 70%;
        width: 20px;
        height: 20px;
        animation-delay: 4s;
    }
    
    .circles li:nth-child(4){
        left: 40%;
        width: 60px;
        height: 60px;
        animation-delay: 0s;
        animation-duration: 18s;
    }
    
    .circles li:nth-child(5){
        left: 65%;
        width: 20px;
        height: 20px;
        animation-delay: 0s;
    }
    
    .circles li:nth-child(6){
        left: 75%;
        width: 110px;
        height: 110px;
        animation-delay: 3s;
    }
    
    .circles li:nth-child(7){
        left: 35%;
        width: 150px;
        height: 150px;
        animation-delay: 7s;
    }
    
    .circles li:nth-child(8){
        left: 50%;
        width: 25px;
        height: 25px;
        animation-delay: 15s;
        animation-duration: 45s;
    }
    
    .circles li:nth-child(9){
        left: 20%;
        width: 15px;
        height: 15px;
        animation-delay: 2s;
        animation-duration: 35s;
    }
    
    .circles li:nth-child(10){
        left: 85%;
        width: 150px;
        height: 150px;
        animation-delay: 0s;
        animation-duration: 11s;
    }
    
    
    
    @keyframes animate {
    
        0%{
            transform: translateY(0) rotate(0deg);
            opacity: 1;
            border-radius: 0;
        }
    
        100%{
            transform: translateY(-1000px) rotate(720deg);
            opacity: 0;
            border-radius: 50%;
        }
    
    }
    
    .sbg{
            -webkit-animation: bounce-in-top 10s infinite both;
                    animation: bounce-in-top 10s infinite both;
    }
    @-webkit-keyframes bounce-in-top {
        0% {
          -webkit-transform: translateY(-500px);
                  transform: translateY(-500px);
          -webkit-animation-timing-function: ease-in;
                  animation-timing-function: ease-in;
          opacity: 0;
        }
        38% {
          -webkit-transform: translateY(0);
                  transform: translateY(0);
          -webkit-animation-timing-function: ease-out;
                  animation-timing-function: ease-out;
          opacity: 1;
        }
        55% {
          -webkit-transform: translateY(-65px);
                  transform: translateY(-65px);
          -webkit-animation-timing-function: ease-in;
                  animation-timing-function: ease-in;
        }
        72% {
          -webkit-transform: translateY(0);
                  transform: translateY(0);
          -webkit-animation-timing-function: ease-out;
                  animation-timing-function: ease-out;
        }
        81% {
          -webkit-transform: translateY(-28px);
                  transform: translateY(-28px);
          -webkit-animation-timing-function: ease-in;
                  animation-timing-function: ease-in;
        }
        90% {
          -webkit-transform: translateY(0);
                  transform: translateY(0);
          -webkit-animation-timing-function: ease-out;
                  animation-timing-function: ease-out;
        }
        95% {
          -webkit-transform: translateY(-8px);
                  transform: translateY(-8px);
          -webkit-animation-timing-function: ease-in;
                  animation-timing-function: ease-in;
        }
        100% {
          -webkit-transform: translateY(0);
                  transform: translateY(0);
          -webkit-animation-timing-function: ease-out;
                  animation-timing-function: ease-out;
        }
      }
      @keyframes bounce-in-top {
    
        38% {
          -webkit-transform: translateY(0);
                  transform: translateY(0);
          -webkit-animation-timing-function: ease-out;
                  animation-timing-function: ease-out;
          opacity: 1;
        }
        55% {
          -webkit-transform: translateY(-65px);
                  transform: translateY(-65px);
          -webkit-animation-timing-function: ease-in;
                  animation-timing-function: ease-in;
        }
        72% {
          -webkit-transform: translateY(0);
                  transform: translateY(0);
          -webkit-animation-timing-function: ease-out;
                  animation-timing-function: ease-out;
        }
        81% {
          -webkit-transform: translateY(-28px);
                  transform: translateY(-28px);
          -webkit-animation-timing-function: ease-in;
                  animation-timing-function: ease-in;
        }
        90% {
          -webkit-transform: translateY(0);
                  transform: translateY(0);
          -webkit-animation-timing-function: ease-out;
                  animation-timing-function: ease-out;
        }
        95% {
          -webkit-transform: translateY(-8px);
                  transform: translateY(-8px);
          -webkit-animation-timing-function: ease-in;
                  animation-timing-function: ease-in;
        }
        100% {
          -webkit-transform: translateY(0);
                  transform: translateY(0);
          -webkit-animation-timing-function: ease-out;
                  animation-timing-function: ease-out;
        }
      }
      
    
    
   
    
    
      /* ==========================Font========================== */
    .cardHover:hover  i{
        color:white;
    
    }
    .cardHover:hover  p{
            color:white;
        
        }
    
    .countBg{
        background: url(../../public/countBg.jpg)no-repeat center center/cover fixed;
        position:relative
    }
    .countBg::after{
        content: "";
        position: absolute;
        top: 0; left: 0; right: 0; bottom: 0;
        width:inherit;height: inherit;
        background: rgba(0,0,0,0.8);
    }
    .skillLi li{
        margin-top: 15px;
    }
    .Box:hover{
        -webkit-box-shadow: 10px 10px 77px 22px rgba(13,110,253,0.63);
    -moz-box-shadow: 10px 10px 77px 22px rgba(13,110,253,0.63);
    box-shadow: 10px 10px 77px 22px rgba(13,110,253,0.63);
    
    }

.Kanit{
        font-family: 'Kanit', sans-serif;
}


